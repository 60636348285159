import firebase from "firebase/compat/app";

export type UserSetting = {
  uid: string | null;
  skipInfomation: boolean;
  diceSkin: DiceSkinSettings;
  agreedTermsAt: firebase.firestore.Timestamp | null;
  markedGamesAt: firebase.firestore.Timestamp | null;
  markedPartnersAt: firebase.firestore.Timestamp | null;
  lastAccessAt: firebase.firestore.Timestamp | null;
  isAnonymous: boolean;
};

export type DiceSkinSettings = {
  d4?: string;
  d6?: string;
  d8?: string;
  d10?: string;
  d12?: string;
  d20?: string;
  d100?: string;
};

export type UpdateUserSetting = Partial<Omit<UserSetting, "uid">>;

export const UserSettingRecord = (
  uid: string | null,
  setting: UpdateUserSetting
): UserSetting => {
  return {
    uid: uid,
    skipInfomation: setting.skipInfomation || false,
    diceSkin: setting.diceSkin || {},
    agreedTermsAt: setting.agreedTermsAt || null,
    markedGamesAt: setting.markedGamesAt || null,
    markedPartnersAt: setting.markedPartnersAt || null,
    lastAccessAt: setting.lastAccessAt || null,
    isAnonymous: setting.isAnonymous || false,
  };
};
