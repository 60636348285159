import {
  Avatar,
  Chip,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { PartnerService } from "api";
import { memo, MouseEvent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "theme";

type ServiceCardProps = {
  service: PartnerService;
  checkUserPlan: (e: MouseEvent) => void;
};

const ServiceCard = ({ service, checkUserPlan }: ServiceCardProps) => {
  return (
    <ServiceListItem
      onClick={checkUserPlan}
      alignItems="flex-start"
      component={Link}
      to={`/partners/${service.id}`}
    >
      <ListItemAvatar
        sx={{
          marginTop: "3px",
        }}
      >
        <ServiceAvater src={service.imageUrl} variant="square" />
      </ListItemAvatar>
      <ListItemText
        sx={{
          margin: 0,
        }}
        primaryTypographyProps={{
          color: "#fff",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontSize: "16px",
        }}
        primary={service.name}
        secondary={
          <>
            <SecondaryArea>{service.detail}</SecondaryArea>
            <TagArea>
              {service.tags.map((tag, index) => (
                <Chip variant="outlined" key={`tag-${index}`} label={tag} />
              ))}
            </TagArea>
          </>
        }
        secondaryTypographyProps={{ component: "span" }}
      />
    </ServiceListItem>
  );
};

const ServiceListItem = styled(ListItemButton)`
  padding: 16px 16px;
  flex-grow: 1;
  cursor: pointer;
`;

const ServiceAvater = styled(Avatar)`
  width: 100px;
  height: 100px;
  margin-right: 16px;
`;

const SecondaryArea = styled.div`
  height: 34px;
  line-height: 17px;
  margin-bottom: 12px;
  margin-top: 4px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  ${theme.breakpoints.down("sm")} {
    height: auto;
  }
`;

const TagArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 5px;
`;

export default memo(ServiceCard);
