import {
  AppBar,
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  getPartnersCampaignByService,
  PartnerCampaign,
  PartnerService,
} from "api";
import { memo, UIEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MarkdownContent from "./MarkdownContent";
import { getAppState } from "stores/modules/app.state/selectors";
import { useAppSelector } from "stores";
import { useMetaTagDescription } from "hooks/useMetaTagDescription";
import theme from "theme";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useModal } from "hooks/useModal";
import sendEvent from "modules/sendEvent";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CcfoliaProLogo } from "containers/svg/CcfoliaProLogo.svg";

const CampaignDialog = () => {
  const [t] = useTranslation();
  const { serviceId } = useParams<{
    serviceId: string;
  }>();
  const [campaignId, setCampaignId] = useState<string | null>(null);
  const location = useLocation();
  const { open, onOpen, onClose } = useModal();
  const isAnonymous = useAppSelector((state) =>
    getAppState(state, "isAnonymous")
  );
  const isPro = useAppSelector((state) => {
    return getAppState(state, "plan") === "ccfolia-pro";
  });
  const [service, setService] = useState<PartnerService | null>(null);
  const [campaigns, setCampaigns] = useState<PartnerCampaign[] | null>(null);
  const campaignRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [isScroll, setIsScroll] = useState(false);

  const handleOnClose = useCallback(() => {
    history.push(`/partners`);
  }, [history]);

  const openServiceCampaign = useCallback(
    async (id: string) => {
      setLoading(true);
      if (isAnonymous || !isPro) {
        history.replace(`/partners`);
        return;
      }

      try {
        const result = await getPartnersCampaignByService(id);
        if (result.error) {
          if (result.error === "isanonymous") {
            window.alert(
              t("特別サービスの利用にはログインとCCFOLIA PROへの加入が必要です")
            );
            return;
          }
          if (result.error === "notprouser") {
            window.alert(
              t("特別サービスの利用はCCFOLIA PROへの加入が必要です")
            );
            return;
          }
        } else {
          const params = new URLSearchParams(location.search);
          const paramsCampaignId = params.get("id");
          await setService(result.service);
          await setCampaigns(result.campaigns);
          const targetCampagin = result.campaigns.filter(
            (campaign) => campaign.campaignId === paramsCampaignId
          )[0];
          if (paramsCampaignId && !targetCampagin) {
            // 指定されているキャンペーン(クーポン)が存在しない場合はエラー
            window.alert(t("指定のキャンペーン情報は見つかりませんでした。"));
            handleOnClose();
            return;
          }
          setCampaignId(paramsCampaignId);
          // エラーなくダイアログが開かれた場合のみイベントを送信
          sendEvent("viewCampaign", {
            service_id: id,
          });
          setLoading(false);
        }
      } catch {
        window.alert(t("キャンペーン情報の取得に失敗しました。"));
        handleOnClose();
      }
    },
    [isAnonymous, isPro, t, handleOnClose, location.search, history]
  );

  useEffect(() => {
    if (!campaignId) return;
    const checkElement = async () => {
      const element = campaignRefs.current[campaignId];
      if (!element) {
        await requestAnimationFrame(checkElement);
        return;
      }

      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 50);
    };
    requestAnimationFrame(checkElement);
  }, [campaignId, open]);

  useEffect(() => {
    if (serviceId) {
      onOpen();
      openServiceCampaign(serviceId);
    } else {
      onClose();
      setCampaignId(null);
      setService(null);
      setCampaigns(null);
      setLoading(false);
    }
  }, [serviceId, openServiceCampaign, onOpen, onClose]);

  const handleScroll = useCallback((e: UIEvent) => {
    const scrollTop = e.currentTarget.scrollTop;
    setIsScroll(scrollTop > 0);
  }, []);

  const targetCampagin = campaigns?.filter(
    (campaign) => campaign.campaignId === campaignId
  )[0];
  const description = targetCampagin
    ? `CCFOLIA PROユーザーに特別サービス！`
    : service
    ? `${service.name} よりCCFOLIA PROユーザーへの優待一覧`
    : `CCFOLIA PROユーザーに、提携サービス・店舗より特別サービス！`;
  useMetaTagDescription(description);

  if (loading)
    return (
      <Backdrop open={loading} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <CircularProgress />
      </Backdrop>
    );

  if (!service || !campaigns) return null;

  return (
    <Dialog
      sx={{
        background: "#202020",
      }}
      open={open}
      onClose={handleOnClose}
      disableRestoreFocus
      fullScreen
    >
      <DialogContent onScroll={handleScroll} sx={{ padding: 0 }}>
        <HeaderBar elevation={0}>
          <HeaderToolBar isscroll={isScroll}>
            <Stack>
              <Box display="flex" alignItems="center">
                <Avatar src="/ccfolia.png" />
                <StyledCcfoliaProIcon />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                overflow="hidden"
                sx={{ maxWidth: "calc(100vw - 90px)" }}
              >
                <Typography
                  variant={matchSm ? "h6" : "body1"}
                  noWrap
                  sx={{
                    flex: "0 1 auto",
                    minWidth: 0,
                  }}
                >
                  {service.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    marginLeft: "5px",
                    flex: 1,
                    minWidth: 0,
                  }}
                >
                  {t("優待一覧")}
                </Typography>
              </Box>
            </Stack>
            <Box flex={1} />
            <IconButton
              onClick={handleOnClose}
              size="large"
              sx={{ alignSelf: "flex-start" }}
            >
              <CloseIcon />
            </IconButton>
          </HeaderToolBar>
        </HeaderBar>
        <HeadingImage src={service.headerImageUrl} />
        <HeaderAvater src={service.imageUrl} />
        <ServiceContents>
          <Typography variant="h5" paragraph fontWeight={700}>
            {service.headerName ? service.headerName : service.name}
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            paragraph
          >
            {service.detail}
          </Typography>
          <Button
            href={service.officialSiteUrl}
            target="_blank"
            variant="outlined"
          >
            {t("公式サイト")}
            <OpenInNewIcon fontSize="small" />
          </Button>
        </ServiceContents>
        <CampaignContents>
          {campaigns.length === 0 && (
            <CampaignBox>
              <Typography textAlign="center">
                {t("現在ご利用可能なキャンペーンがありません")}
              </Typography>
            </CampaignBox>
          )}
          <Stack divider={<Divider />}>
            {campaigns.map((campaign) => (
              <CampaignBox
                ref={(el: HTMLDivElement) =>
                  (campaignRefs.current[campaign.campaignId] = el)
                }
                key={`campaign-card-${campaign.campaignId}`}
                id={`campaign-card-${campaign.campaignId}`}
              >
                <Chip
                  sx={{ marginBottom: "8px" }}
                  size="small"
                  variant="outlined"
                  label={t("PRO優待")}
                />
                <Typography
                  variant={matchSm ? "h4" : "h5"}
                  fontWeight={700}
                  gutterBottom
                >
                  {campaign.title}
                </Typography>
                {campaign.termTitle && (
                  <Typography variant="body1" gutterBottom>
                    📅 {campaign.termTitle}：{campaign.campaignTerm}
                  </Typography>
                )}
                {campaign.summary && (
                  <Typography
                    variant="body1"
                    color={theme.palette.text.secondary}
                  >
                    {campaign.summary}
                  </Typography>
                )}
                <CampaignImage src={campaign.imageUrl} />
                <MarkdownContent text={campaign.detail} />
              </CampaignBox>
            ))}
          </Stack>
        </CampaignContents>
      </DialogContent>
    </Dialog>
  );
};

const HeaderBar = styled(AppBar)`
  position: fixed;
  background: transparent;
`;

const HeaderToolBar = styled(Toolbar)<{ isscroll: boolean }>`
  padding-top: 16px;
  padding-bottom: 16px;
  ${theme.breakpoints.down("sm")} {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      rgba(32, 32, 32, 0.8) 0%,
      transparent 100%
    );
    opacity: ${(props) => (props.isscroll ? "1" : "0")};
    transition: 0.3s opacity ease-out;
    z-index: -1;
  }
`;

const HeadingImage = styled.img`
  height: 180px;
  width: 100%;
  object-fit: cover;
`;

const HeaderAvater = styled(Avatar)`
  width: 200px;
  height: 200px;
  margin: -100px auto 16px;
  ${theme.breakpoints.down("sm")} {
    width: 160px;
    height: 160px;
    margin: -80px auto 16px;
  }
`;
const StyledCcfoliaProIcon = styled(CcfoliaProLogo)`
  margin-left: 7px;
  height: 15px;
  width: auto;
  max-width: calc(100vw - 32px);
  fill: ${theme.palette.text.primary};
`;

const ServiceContents = styled(Box)`
  max-width: 400px;
  padding: 0 40px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CampaignContents = styled(Box)`
  width: min(640px, calc(100% - 32px));
  margin: auto auto 80px;
  ${theme.breakpoints.down("sm")} {
    margin: auto auto 40px;
  }
`;

const CampaignBox = styled(Box)`
  width: 100%;
  margin: 40px 0;
  scroll-margin: 100px;
`;
const CampaignImage = styled.img`
  margin: 24px 0;
  width: 100%;
  height: auto;
`;

export default memo(CampaignDialog);
