import { useCallback, useState, memo, useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "stores";

import JoinedProDialog from "containers/JoinedProDialog";
import { useObserveSigninError } from "modules/signin";
import PartnerContent from "containers/Partners";
import Header from "containers/Header";
import Login from "containers/Login";
import PlanSelect from "containers/PlanSelect";
import { subscribeUserState } from "stores/modules/app.user/operations";
import StripeRedirectObserver from "containers/Home/StripeRedirectObserver";
import ConfirmTermsAgreement from "containers/ConfirmTermsAgreement";
import UserSettings from "containers/UserSettings";
import { getAppState } from "stores/modules/app.state/selectors";
import { getAuthedUid } from "stores/modules/app.user/selectors";
import {
  loadUserSetting,
  subscribeUserSetting,
} from "stores/modules/entities.user.setting/operations";
import UserDeleteDialog from "containers/UserDeleteDialog";

const Partners = () => {
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const dispatch = useAppDispatch();
  const uid = useAppSelector((state) => getAppState(state, "uid"));
  const authedUid = useAppSelector(getAuthedUid);
  const settingsUid = useAppSelector((state) => state.entities.userSetting.uid);

  useEffect(() => {
    const cleanup = dispatch(subscribeUserState());
    return () => {
      dispatch(cleanup);
    };
  }, [dispatch]);

  useEffect(() => {
    if (authedUid) {
      dispatch(loadUserSetting(authedUid));
    }
  }, [dispatch, authedUid]);

  useEffect(() => {
    if (settingsUid) {
      return dispatch(subscribeUserSetting(settingsUid));
    }
  }, [dispatch, settingsUid]);

  const onOpenLoginForm = useCallback(() => {
    setOpenLoginForm(true);
  }, [setOpenLoginForm]);

  const onCloseLoginForm = useCallback(() => {
    setOpenLoginForm(false);
  }, [setOpenLoginForm]);

  useObserveSigninError({ openLoginDialog: onOpenLoginForm });

  return (
    <Wrapper>
      <Header
        position="relative"
        onOpenLogin={onOpenLoginForm}
        isDispUserSettings={true}
      />
      <PartnerContent onOpenLogin={onOpenLoginForm} />
      <UserSettings uid={uid} />
      <PlanSelect />
      <UserDeleteDialog />
      <StripeRedirectObserver />
      <ConfirmTermsAgreement />
      <Login open={openLoginForm} onClose={onCloseLoginForm} />
      <JoinedProDialog />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

export default memo(Partners);
