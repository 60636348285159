import React from "react";
import { Router, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { store, history, persistor } from "./stores";
import { PersistGate } from "redux-persist/integration/react";

import Welcome from "pages/Welcome";
import Home from "pages/Home";
import Room from "pages/Room";
import RoomCharacter from "pages/RoomCharacter";
import RoomChat from "pages/RoomChat";

import UpdateNotification from "containers/UpdateNotification";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme";

import enJson from "./locales/en.json";
import jaJson from "./locales/ja.json";
import koJson from "./locales/ko.json";
import zhTwJson from "./locales/zh-TW.json";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Auth from "modules/firebase-utils/Auth";
import Plan from "pages/Plan";
import DeprecatedPreview from "pages/DeprecatedPreview";
import RoomPackage from "pages/RoomPackage";
import ResetPassword from "pages/ResetPassword";
import PDFTool from "pages/PDFTool";
import Partners from "pages/Partners";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: enJson },
      ja: { translation: jaJson },
      ko: { translation: koJson },
      zhTw: { translation: zhTwJson },
    },
    // lng: "ja",
    fallbackLng: {
      ko: ["en"],
      zhTw: ["en"],
    },
    interpolation: { escapeValue: false },
    returnEmptyString: false,
    react: {
      wait: true,
    },
  });

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Auth />
            <Router history={history}>
              <Route exact path="/" component={Welcome} />
              <Route
                exact
                path={[
                  "/home",
                  "/games",
                  "/games/:productId",
                  "/library",
                  "/library/:productId",
                ]}
                component={Home}
              />
              <Route
                exact
                path="/plans-closed-beta"
                component={RedirectToPlans}
              />
              <Route exact path="/plans" component={Plan} />
              <Route exact path="/rooms/:roomId" component={Room} />
              <Route
                exact
                path="/rooms-v2/:roomId"
                component={DeprecatedPreview}
              />
              <Route
                exact
                path="/rooms/:roomId/characters/:characterId"
                component={RoomCharacter}
              />
              <Route exact path="/rooms/:roomId/chat" component={RoomChat} />
              <Route
                exact
                path="/room-packages/:roomPackageId"
                component={RoomPackage}
              />
              <Route exact path="/tools/pdf" component={PDFTool} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route
                exact
                path={["/partners", "/partners/:serviceId"]}
                component={Partners}
              />
            </Router>
            <UpdateNotification />
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
};

const RedirectToPlans = () => {
  return <Redirect to="/plans" />;
};

export default App;
