import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  Container,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PartnerCampaign } from "api";
import { MouseEvent, useCallback, useRef } from "react";
import Slider, { Settings as SliderSettings } from "react-slick";
import styled from "styled-components";
import theme from "theme";
import { Link } from "react-router-dom";

type CarouselProps = {
  campaigns: PartnerCampaign[];
  checkUserPlan: (e: MouseEvent) => void;
};

const CampaignCarousel = ({ campaigns, checkUserPlan }: CarouselProps) => {
  const sliderRef = useRef<Slider>(null);

  const items = campaigns.length < 3 ? campaigns.length + 1 : campaigns.length;

  const settings: SliderSettings = {
    arrows: false,
    className: "center",
    centerMode: true,
    centerPadding: "0",
    infinite: items > 1,
    speed: 500,
    slidesToShow: 1,
    draggable: false,
    dots: true,
    variableWidth: true,
  };

  const moveForward = useCallback(() => {
    sliderRef.current?.slickNext();
  }, [sliderRef]);

  const moveBackward = useCallback(() => {
    sliderRef.current?.slickPrev();
  }, [sliderRef]);

  return (
    <Box position="relative" overflow="hidden">
      <SliderContainer items={items}>
        <Slider {...settings} ref={sliderRef}>
          {campaigns.map((campaign) => {
            return (
              <CarouselItem
                key={campaign.id}
                campaignId={campaign.campaignId}
                serviceId={campaign.serviceId}
                thumbnailUrl={campaign.topImageUrl}
                checkUserPlan={checkUserPlan}
              />
            );
          })}
          {campaigns.length < 3 && (
            <>
              <CarouselItemBox className="carousel-item">
                <Card
                  sx={{
                    position: "relative",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                  elevation={0}
                >
                  <CardActionArea
                    href="#recruit-partners"
                    sx={{ height: "100%" }}
                  >
                    <img
                      src="/images/partners-banner.png"
                      width="100%"
                      height="100%"
                      alt=""
                    />
                  </CardActionArea>
                </Card>
              </CarouselItemBox>
            </>
          )}
        </Slider>
      </SliderContainer>
      <ArrowButton align="right" items={items} onClick={moveForward}>
        <ArrowForwardIosIcon sx={{ color: "text.primary" }} />
      </ArrowButton>
      <ArrowButton align="left" items={items} onClick={moveBackward}>
        <ArrowBackIosNewIcon sx={{ color: "text.primary" }} />
      </ArrowButton>
    </Box>
  );
};

const SliderContainer = styled(Container)<{ items: number }>`
  width: 100vw;
  max-width: ${({ items }) => 343 * (items * 2 + 1) + 24 * (items * 2)}px;
  overflow: hidden;
  margin: 40px auto 0;

  .slick-slide {
    max-width: 370px;
    max-height: 140px;
    ${theme.breakpoints.down("sm")} {
      max-width: 100%;
    }
  }
  .slick-dots {
    padding-top: 1px;
    position: static;
    li button:before {
      font-size: 8px;
      color: #606060;
      opacity: 1;
    }
    li.slick-active button:before {
      color: rgba(244, 0, 87, 1);
      opacity: 1;
    }
    ${theme.breakpoints.down("sm")} {
      padding-top: 10px;
    }
  }

  .item-description {
    opacity: 0;
  }

  .slick-center .item-description {
    opacity: 1;
  }

  .slick-list {
    overflow: visible;
  }
`;

const ArrowButton = styled(ButtonBase)<{
  align: "left" | "right";
  items: number;
}>`
  background: #000;
  padding: 16px;
  border-radius: 0;

  position: absolute;
  top: calc(50% - 16px - 12px);
  ${({ align }) => align}: max(28px, calc(50vw - ${({ items }) =>
    343 * (items * 2 + 1) + 24 * (items * 2)}px / 2) - 80px);

  &:hover {
    background: ${theme.palette.grey[800]};
  }
  ${theme.breakpoints.down("sm")} {
    ${({ align }) => align}: 0;
    padding: 8px;
    top: calc(50% - 8px - 12px);
  }
`;

type CarouselItemProps = {
  campaignId: string;
  serviceId: string;
  thumbnailUrl: string;
  checkUserPlan: (e: MouseEvent) => void;
};

const CarouselItem = ({
  campaignId,
  serviceId,
  thumbnailUrl,
  checkUserPlan,
}: CarouselItemProps) => {
  return (
    <CarouselItemBox id={`card-${campaignId}`} className="carousel-item">
      <Card
        sx={{ position: "relative", height: "100%", borderRadius: "8px" }}
        elevation={0}
      >
        <CardActionArea
          onClick={checkUserPlan}
          component={Link}
          to={`/partners/${serviceId}?id=${campaignId}`}
          sx={{ height: "100%" }}
        >
          <img
            src={thumbnailUrl}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            alt=""
          />
        </CardActionArea>
      </Card>
    </CarouselItemBox>
  );
};

const CarouselItemBox = styled(Box)`
  max-width: min(100vw - 128px, 343px);
  width: 343px;
  max-height: 140px;
  aspect-ratio: 39 / 16;
  padding: 0 12px;
  height: auto;
  ${theme.breakpoints.down("sm")} {
    padding: 0 6px;
  }
`;

export default CampaignCarousel;
