import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PartnerCampaign, PartnerService } from "api";
import { memo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import ServiceCard from "./ServiceCard";
import styled from "styled-components";
import theme from "theme";

type ServiceProps = {
  services: PartnerService[];
  checkUserPlan: (e: MouseEvent) => void;
};

export type ServiceCampaign = {
  service: PartnerService;
  campaigns: PartnerCampaign[];
};

const Service = ({ services, checkUserPlan }: ServiceProps) => {
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [t] = useTranslation();

  return (
    <>
      <Title color="textPrimary" variant={matchSm ? "h4" : "h5"}>
        {t("利用できるサービス・店舗")}
      </Title>
      <MainContainer>
        <GridCOntainer container spacing={3} alignItems="stretch">
          {services.map((service) => (
            <Grid item xs={12} md={6} key={service.id}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <ServiceCard service={service} checkUserPlan={checkUserPlan} />
                <Divider />
              </Box>
            </Grid>
          ))}
        </GridCOntainer>
      </MainContainer>
    </>
  );
};

const MainContainer = styled(Container)`
  max-width: 808px;
`;
const Title = styled(Typography)`
  margin-top: 81px;
  margin-bottom: 16px;
  font-weight: 700;
  text-align: center;

  ${theme.breakpoints.down("sm")} {
    margin: 64px auto 0;
  }
`;

const GridCOntainer = styled(Grid)`
  margin-top: 12px;
  .MuiGrid-item {
    padding-top: 0;
  }
`;

export default memo(Service);
