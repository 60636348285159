import { functions, db, auth } from "./initializer";
import version from "version";
import compareVersions from "compare-versions";
import { escape, forEach, template } from "lodash-es";
import { saveAs } from "file-saver";
import { getIdToken } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import {
  Snapshot,
  UpdateSnapshot,
} from "stores/modules/entities.room.savedatas";

const diceroll = httpsCallable<
  { command: string; system: string },
  {
    result: string;
    secret: boolean;
    success: boolean;
    failure: boolean;
    critical: boolean;
    fumble: boolean;
    dices: { kind: string; faces: number; value: number }[];
  } | null
>(functions, "diceroll");
const systeminfo = httpsCallable<
  { system: string },
  { systeminfo: { info: string } }
>(functions, "systeminfo");
const commandPattern = httpsCallable<{ system: string }, string>(
  functions,
  "commandPattern"
);
const dicebotVersion = httpsCallable<void, string>(functions, "dicebotVersion");
const names = httpsCallable<
  void,
  { names: { name: string; system: string; sortKey: string }[] }
>(functions, "names");
const getDeployedVersion = httpsCallable<void, string>(functions, "version");
const _getTermsUpdatedAt = httpsCallable<void, string>(
  functions,
  "termsUpdatedAt"
);
const recursiveDeleteRoomData = httpsCallable<{ path: string; roomId: string }>(
  functions,
  "recursiveDeleteRoomData"
);
const duplicateRoom = httpsCallable<{
  roomId: string;
  subcollections?: string[];
}>(functions, "duplicateRoom");
const getFileMetadataCall = httpsCallable<string, any>(
  functions,
  "getFileMetadata"
);
const deleteFileCall = httpsCallable<string, boolean>(functions, "deleteFile");
const deleteAccountCall = httpsCallable<void, boolean>(
  functions,
  "deleteAccount"
);
const _getKanbanAds = httpsCallable<void, KanbanAd[]>(
  functions,
  "getKanbanAds"
);
const _getProducts = httpsCallable<void, Product[]>(functions, "getProducts");
const _getProductById = httpsCallable<{ productId: string }, Product>(
  functions,
  "getProductById"
);
const _getProductsLatestPublishedAt = httpsCallable<void, string>(
  functions,
  "getProductsLatestPublishedAt"
);
const _getGamesProduct = httpsCallable<{ productId: string }, Product>(
  functions,
  "getGamesProduct"
);
const _getGamesProductsInLibrary = httpsCallable<void, Product[]>(
  functions,
  "getGamesProductsInLibrary"
);
const _getGamesPurchase = httpsCallable<
  { productId: string },
  { purchased: boolean }
>(functions, "getGamesPurchase");
const _createProductCheckoutUrl = httpsCallable<
  { productId: string },
  { url: string }
>(functions, "createProductCheckoutUrl");
const _createFreeProductPurchase = httpsCallable<
  { productId: string },
  { purchased: boolean }
>(functions, "createFreeProductPurchase");
const _syncPurchaseState = httpsCallable<
  { productId: string },
  { purchased: boolean }
>(functions, "syncPurchaseState");
const _getGamesPurchasedProductIds = httpsCallable<void, string[]>(
  functions,
  "getGamesPurchasedProductIds"
);
const _getDownloadAttachmentUrl = httpsCallable<
  { productId: string; filename: string },
  { url: string }
>(functions, "getDownloadAttachmentUrl");
const _createSubscriptionSession = httpsCallable<
  { plan: string },
  { url: string }
>(functions, "createSubscriptionSession");
const _createBillingPortalSession = httpsCallable<void, { url: string }>(
  functions,
  "createBillingPortalSession"
);
const _getPaymentCount = httpsCallable<void, number>(
  functions,
  "getPaymentCount"
);
const _getOfficialPlaylists = httpsCallable<void, OfficialPlaylist[]>(
  functions,
  "getOfficialPlaylists"
);
const _getOfficialImages = httpsCallable<void, OfficialImageDir[]>(
  functions,
  "getOfficialImages"
);
const _createSnapshot = httpsCallable<
  { roomId: string },
  { snapshotId: string }
>(functions, "createSnapshot");
const _importSnapshot = httpsCallable<{
  roomId: string;
  snapshotId: string;
  snapshot: Snapshot;
}>(functions, "importSnapshot");
const _getSnapshot = httpsCallable<
  { roomId: string; snapshotId: string },
  UpdateSnapshot
>(functions, "getSnapshot");
const _deleteSnapshot = httpsCallable<{ roomId: string; snapshotId: string }>(
  functions,
  "deleteSnapshot"
);

const _syncSubscriptionState = httpsCallable<
  { force: boolean },
  "free" | "ccfolia-pro"
>(functions, "syncSubscriptionState");
const _publishRoomPackage = httpsCallable<
  { roomId: string },
  { roomPackageId: string }
>(functions, "publishRoomPackage");
const _getRoomPackage = httpsCallable<unknown, RoomPackageInfo>(
  functions,
  "getRoomPackage"
);
const _deleteRoomPackage = httpsCallable<{ roomPackageId: string }, void>(
  functions,
  "deleteRoomPackage"
);
const _createRoomFromRoomPackage = httpsCallable<
  { roomPackageId: string },
  { roomId: string }
>(functions, "createRoomFromRoomPackage");
const _applyRoomPackage = httpsCallable<
  { roomPackageId: string; roomId: string; applyOptions: ApplyOptions },
  void
>(functions, "applyRoomPackage");
const _getPartnersService = httpsCallable<void, ServiceInfo>(
  functions,
  "getPartnersService"
);
const _getServiceCampaign = httpsCallable<
  { serviceId: string },
  ServiceCampaign
>(functions, "getCampaignsByService");
const _getCampaignsLatestPublishedAt = httpsCallable<void, string>(
  functions,
  "getCampaignsLatestPublishedAt"
);

// functions dev
// functions.useFunctionsEmulator("http://localhost:5000");

const CF_ENDPOINT = process.env.REACT_APP_CF_ENDPOINT;
// const CF_ENDPOINT = "http://localhost:5000/ccfolia-160aa/asia-northeast1";

export const checkClientVersion = async () => {
  if (process.env.NODE_ENV !== "production") {
    return null;
  }
  try {
    const res = await getDeployedVersion();
    // const res = { data: "1.6.1" };
    const compared = compareVersions(res.data, version);
    return compared > 0 ? res.data : null;
  } catch (_) {
    return null;
  }
};

export const deleteAllRoomMessages = async (roomId: string) => {
  try {
    return recursiveDeleteRoomData({ roomId, path: "messages" });
  } catch (err) {
    return err;
  }
};

export const getDiceBotVersion = async () => {
  try {
    const res = await dicebotVersion();
    if (res.data) {
      return res.data + "";
    }
  } catch (err) {}
  return "";
};

export const getDiceBotSystems = async () => {
  try {
    const res = await names();
    if (res.data) {
      return res.data;
    }
  } catch (err) {}
};

export const getDiceBotSystemsInfo = async (system: string | null) => {
  try {
    if (system == null) {
      return;
    }

    const res = await systeminfo({ system });
    if (res.data) {
      return res.data;
    }
  } catch (err) {}
};

const commandPatternCache: { [system: string]: RegExp } = {};
export const getDiceBotCommandPattern = async (system: string) => {
  const pattern = commandPatternCache[system];
  if (pattern) {
    return pattern;
  }

  try {
    const res = await commandPattern({ system });
    if (typeof res.data === "string") {
      const regexp = new RegExp(res.data, "i");
      commandPatternCache[system] = regexp;
      return regexp;
    }
  } catch (err) {}
};

const convertHalfWidthText = (text: string) => {
  return text
    .replace(/[！-～]/g, (str) => {
      return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
    })
    .replace(/”/g, '"')
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    .replace(/　/g, " ")
    .replace(/〜/g, "~");
};
export const sendDiceBotCommand = async (
  system: string | null,
  command: string
) => {
  try {
    command = convertHalfWidthText(command);
    const commandPattern = await getDiceBotCommandPattern(system || "");
    if (commandPattern == null || !commandPattern.test(command)) {
      return null;
    }
    const res = await diceroll({ system: system || "", command });
    if (res.data) {
      return res.data;
    }
  } catch (err) {}
};

export const duplicateRoomFunc = async ({
  roomId,
  subcollections,
}: {
  roomId: string;
  subcollections: string[];
}) => {
  try {
    const res = await duplicateRoom({ roomId, subcollections });
    window.location.href = "/home"; // temporary fix
    return res;
  } catch (err) {
    return err;
  }
};

const LogHTMLMessagesTemplate = `
<% forEach(messages, function(message) { %>
<p style="color:<%- message.color %>;">
  <span><% if(message.channelName) { %> [<%- message.channelName %>]<% } %></span>
  <span><%- message.name %></span> <% if (message.to) { %> >> <span><%- message.toName %></span><% } %>:
  <span>
    <%= escape(message.text).split('\\n').join('<br>') %> <% if (message.extend.roll) { %><%- message.extend.roll.result %><% } %>
  </span>
</p>
<% }); %>`;
const renderMessageElementsHTML = template(LogHTMLMessagesTemplate);

type DownloadRoomLogOptions = {
  uid: string;
  roomId: string;
  includeSecret: boolean;
  channel: string | null;
  channelName: string;
  isPro: boolean;
};
export const downloadRoomLog = async (
  {
    uid,
    roomId,
    includeSecret,
    channel,
    channelName,
    isPro,
  }: DownloadRoomLogOptions,
  updateProgress: (value: number, total: number) => void
) => {
  const roomRef = doc(db, "rooms", roomId);
  const roomSnapshot = await getDoc(roomRef);
  const room = roomSnapshot.data();
  if (!room) return;
  const fileName = `${room.name}[${channelName}].html`;
  const messagesRef = collection(roomRef, "messages");
  const outputMaxSize = isPro ? 100_000 : 10_000;

  const getMessageTotalCount = async (channel: string | null) => {
    const messageCountQuery = channel
      ? query(messagesRef, where("channel", "==", channel))
      : messagesRef;
    const snapshot = await getCountFromServer(messageCountQuery);
    return snapshot.data().count;
  };

  const totalCount = Math.min(
    await getMessageTotalCount(channel),
    outputMaxSize
  );

  const createMessageQuery = (
    channel: string | null,
    startAfterDocument: QueryDocumentSnapshot<DocumentData> | null,
    limitSize: number
  ) => {
    const baseQuery = channel
      ? query(
          messagesRef,
          where("channel", "==", channel),
          orderBy("createdAt", "desc"),
          limit(limitSize)
        )
      : query(messagesRef, orderBy("createdAt", "desc"), limit(limitSize));

    return startAfterDocument
      ? query(baseQuery, startAfter(startAfterDocument))
      : baseQuery;
  };

  const getMessageElements = async (
    startAfterDocument: QueryDocumentSnapshot<DocumentData> | null,
    rendered: string,
    messages: number,
    limitSize: number,
    max: number
  ) => {
    const messagesQuery = createMessageQuery(
      channel,
      startAfterDocument,
      limitSize
    );
    const messagesSnapshot = await getDocs(messagesQuery);
    const { docs, size } = messagesSnapshot;
    const elements = renderMessageParagraphs(messagesSnapshot) + rendered;
    const sizeFetchedMessages = messages + size;

    updateProgress(sizeFetchedMessages, totalCount);

    if (size > 0 && max > sizeFetchedMessages) {
      return getMessageElements(
        docs[docs.length - 1],
        elements,
        messages + size,
        limitSize,
        max
      );
    } else {
      return elements;
    }
  };

  const renderMessageParagraphs = (snapshot: QuerySnapshot): string => {
    const messages = snapshot.docs
      .map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      })
      .filter((message) => {
        if (includeSecret) {
          return !message.to || message.from === uid || message.to === uid;
        } else {
          return !message.to;
        }
      })
      .reverse()
      .map((message) => {
        if (
          room.owner !== uid &&
          message.from !== uid &&
          message.extend.roll &&
          message.extend.roll.secret
        ) {
          message.text = "シークレットダイス";
          message.extend.roll.result = "???";
          message.extend.roll.dices = [];
        }
        return message;
      });

    return renderMessageElementsHTML({ messages, escape, forEach });
  };

  const elements = await getMessageElements(null, "", 0, 1000, outputMaxSize);
  const resultHtml = `<!DOCTYPE html>
<html lang="ja">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>ccfolia - logs</title>
  </head>
  <body>
    ${elements}
  </body>
</html>`;
  const blob = new Blob([resultHtml], {
    type: "text/html",
  });
  saveAs(blob, fileName);
};

export const uploadFile = async ({ file, filePath }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("filePath", filePath);
  try {
    const idToken = auth.currentUser
      ? await getIdToken(auth.currentUser)
      : undefined;
    const res = await fetch(`${CF_ENDPOINT}/uploadFile`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return res.json();
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getFileMetadata = async (filePath: string) => {
  try {
    const res = await getFileMetadataCall(filePath);
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteFile = async (filePath: string) => {
  try {
    const res = await deleteFileCall(filePath);
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteAccount = async () => {
  try {
    const res = await deleteAccountCall();
    if (res.data) {
      window.location.reload();
      return true;
    }
  } catch (err) {
    console.error(err);
  }
  return false;
};

export type KanbanAd = {
  kind: "ad" | "games";
  campaignId: string;
  name: string;
  imageUrl: string;
  videoUrl: string;
  muted: boolean;
  url: string;
};

export const getKanbanAds = async (): Promise<KanbanAd[]> => {
  const res = await _getKanbanAds();
  return res.data;
};

export const getProductsLatestPublishedAt = async (): Promise<string> => {
  const res = await _getProductsLatestPublishedAt();
  return res.data;
};

export type Product = {
  id: string;
  name: string;
  shortName: string;
  kind: "game" | "extention";
  category: string;
  players: string;
  playTimes: string;
  developer: string;
  officialSite?: {
    name: string;
    url: string;
  };
  releasedAt: string;
  publishedAt: string; // ISO Date String
  notAvailable: boolean;
  price: number;
  contents: string;
  description: string;
  details: string;
  thumbnailUrl: string;
  carouselItems: Visual[];
  roomPackageId?: string;
  applyOptions?: ApplyOptions;
  externalShopUrl?: string;
  parentProduct?: Product;
  relatedProducts: Product[]; // 追加・拡張(公開済商品)
  candidateRelatedProducts: Product[]; // 追加・拡張(購入済候補商品)
  relatedItems: Product[]; // 関連商品
  attachments: ProductAttachment[];
  isOther: boolean;
};

type ProductAttachment = {
  filename: string;
  size: string;
  mime: string;
  updatedAt: string; // ISO Date String
};

export type Visual = VisualImage | VisualVideo;

export type VisualImage = {
  kind: "image";
  url: string;
};

export type VisualVideo = {
  kind: "video";
  url: string;
  thumbnailUrl: string;
};

export const getProducts = async (): Promise<Product[]> => {
  const res = await _getProducts();
  return res.data;
};

export const getProductById = async (productId: string): Promise<Product> => {
  const res = await _getProductById({ productId });
  return res.data;
};

export const getGamesProduct = async (
  productId: string
): Promise<Product | null> => {
  try {
    const res = await _getGamesProduct({ productId });
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    return null;
  }
  return null;
};

export const getGamesProductsInLibrary = async (): Promise<Product[]> => {
  const res = await _getGamesProductsInLibrary();
  return res.data;
};

export const getGamesPurchase = async (
  productId: string
): Promise<{ purchased: boolean }> => {
  const res = await _getGamesPurchase({ productId });
  return res.data;
};

export const createProductCheckoutUrl = async (
  productId: string
): Promise<{ url: string }> => {
  const res = await _createProductCheckoutUrl({ productId });
  return res.data;
};

export const getDownloadAttachmentUrl = async (
  productId: string,
  filename: string
): Promise<{ url: string }> => {
  const res = await _getDownloadAttachmentUrl({ productId, filename });
  return res.data;
};

export const createFreeProductPurchase = async (
  productId: string
): Promise<{ purchased: boolean }> => {
  const res = await _createFreeProductPurchase({ productId });
  return res.data;
};

export const syncPurchaseState = async (
  productId: string
): Promise<{ purchased: boolean }> => {
  const res = await _syncPurchaseState({ productId });
  return res.data;
};

export const getGamesPurchasedProductIds = async () => {
  const res = await _getGamesPurchasedProductIds();
  return res.data;
};

export const getTermsUpdatedAt = async () => {
  try {
    const res = await _getTermsUpdatedAt();
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    return null;
  }
  return null;
};

export const createSubscription = async (): Promise<{ url: string }> => {
  try {
    const res = await _createSubscriptionSession({ plan: "ccfolia-pro" });
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const createBillingPortalSession = async (): Promise<{
  url: string;
}> => {
  try {
    const res = await _createBillingPortalSession();
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const getPaymentCount = async (): Promise<number> => {
  const res = await _getPaymentCount();
  return res.data;
};

export type OfficialPlaylist = {
  id: string;
  name: string;
  free: boolean;
  sounds: PlaylistSound[];
};

export type PlaylistSound = {
  id: string;
  name: string;
  provider: string;
  volume: number;
  repeat: boolean;
  url: string;
};

export const getOfficialPlaylists = async (): Promise<OfficialPlaylist[]> => {
  try {
    const res = await _getOfficialPlaylists();
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const syncSubscriptionState = async (options: {
  force: boolean;
}): Promise<"free" | "ccfolia-pro"> => {
  try {
    const res = await _syncSubscriptionState(options);
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const publishRoomPackage = async (
  roomId: string
): Promise<{ roomPackageId: string }> => {
  try {
    const res = await _publishRoomPackage({ roomId });
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export type RoomPackageInfo = {
  ownerDigest: string;
  ownerName: string;
  name: string;
  thumbnailUrl?: string | null;
  productId: string | null;
  purchased: boolean;
  createdAt: string;
  updatedAt: string;
};

export const getRoomPackage = async (
  roomPackageId: string
): Promise<RoomPackageInfo> => {
  try {
    const res = await _getRoomPackage({ roomPackageId });
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const deleteRoomPackage = async (
  roomPackageId: string
): Promise<void> => {
  try {
    await _deleteRoomPackage({ roomPackageId });
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const createRoomFromRoomPackage = async (
  roomPackageId: string
): Promise<{ roomId: string }> => {
  try {
    const res = await _createRoomFromRoomPackage({ roomPackageId });
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export type ApplyOptions = {
  room: boolean;
  characters: boolean;
  decks: boolean;
  effects: boolean;
  items: boolean;
  notes: boolean;
  scenes: boolean;
  savedatas: boolean;
};

export const applyRoomPackage = async (
  roomId: string,
  roomPackageId: string,
  applyOptions: ApplyOptions
) => {
  await _applyRoomPackage({ roomId, roomPackageId, applyOptions });
  return;
};

export const createSnapshot = async (
  roomId: string
): Promise<{ snapshotId: string }> => {
  try {
    const res = await _createSnapshot({ roomId });
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const importSnapshot = async (
  roomId: string,
  snapshotId: string,
  snapshot: Snapshot
): Promise<unknown> => {
  try {
    const res = await _importSnapshot({ roomId, snapshotId, snapshot });
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const getSnapshot = async (
  roomId: string,
  snapshotId: string
): Promise<UpdateSnapshot> => {
  try {
    const res = await _getSnapshot({ roomId, snapshotId });
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const deleteSnapshot = async (
  roomId: string,
  snapshotId: string
): Promise<void> => {
  try {
    await _deleteSnapshot({ roomId, snapshotId });
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export type OfficialImageDir = {
  id: string;
  name: string;
  sortKey: number;
  images: OfficialImage[];
};

export type OfficialImage = {
  id: string;
  name: string;
  url: string;
};

export const getOfficialImageDirs = async (): Promise<OfficialImageDir[]> => {
  try {
    const res = await _getOfficialImages();
    const data = res.data;
    return data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export type PartnerService = {
  id: string;
  name: string;
  headerName: string;
  detail: string;
  imageUrl: string;
  headerImageUrl: string;
  officialSiteUrl: string;
  order: number;
  tags: string[];
};

export type PartnerCampaign = {
  id: string;
  serviceId: string;
  campaignId: string;
  title: string;
  detail: string;
  summary: string;
  imageUrl: string;
  specialOfferImageUrl: string;
  topImageUrl: string;
  termTitle: string;
  campaignTerm: string;
  publishedAt: string;
  isSpecialOffer: boolean;
  isTop: boolean;
  order: number;
};

export type ServiceInfo = {
  services: PartnerService[];
  topCampaigns: PartnerCampaign[];
  specialCampaign?: PartnerCampaign;
};

export type ServiceCampaign = {
  error?: string;
  service: PartnerService;
  campaigns: PartnerCampaign[];
};

export const getPartnersService = async (): Promise<ServiceInfo> => {
  const res = await _getPartnersService();
  return res.data;
};

export const getPartnersCampaignByService = async (
  serviceId: string
): Promise<ServiceCampaign> => {
  try {
    const res = await _getServiceCampaign({ serviceId });
    return res.data;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const getCampaignsLatestPublishedAt = async (): Promise<string> => {
  const res = await _getCampaignsLatestPublishedAt();
  return res.data;
};
