import styled from "styled-components";
import theme from "theme";
import { Trans, useTranslation } from "react-i18next";

import { Button, Container, Typography, useMediaQuery } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const PartnersInfo = () => {
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [t] = useTranslation();

  return (
    <>
      <MainContainer>
        <Title color="textPrimary" variant={matchSm ? "h5" : "h6"}>
          {t("ご利用に関する注意事項")}
        </Title>
        <NoteTypo color="textSecondary">
          {t(
            "・キャンペーンは予告なく終了、あるいは内容が変更される可能性がございます。"
          )}
        </NoteTypo>
        <NoteTypo color="textSecondary">
          {t(
            "・店舗クーポンは、混雑状況によってはご利用いただけない場合があります。あらかじめご了承ください。"
          )}
        </NoteTypo>
        <NoteTypo color="textSecondary">
          {t(
            "・各サービスに関するお問い合わせは、各店舗様へお願いいたします。"
          )}
        </NoteTypo>
      </MainContainer>
      <MainContainer sx={{ textAlign: "center" }} id="recruit-partners">
        <Title color="textPrimary" variant={matchSm ? "h5" : "h6"}>
          {t("提携サービス・店舗募集中！")}
        </Title>
        <DetailText sx={{ color: "#fff" }}>
          <Trans>
            オンラインTRPGプレイヤーに、
            <br style={{ display: matchSm ? "none" : "block" }} />
            あなたのお店を届けませんか？
          </Trans>
        </DetailText>
        <Spacer />
        <Button
          variant="outlined"
          href="mailto:contact@ccfolia.com"
          sx={{ padding: "8px 22px", whiteSpace: "nowrap" }}
        >
          <MailOutlineIcon sx={{ marginRight: "5px" }} />
          {t("お問い合わせはこちら")}
        </Button>
        <DetailText color="textSecondary">contact@ccfolia.com</DetailText>
      </MainContainer>
    </>
  );
};

const MainContainer = styled(Container)`
  max-width: 760px;
`;

const NoteTypo = styled(Typography)`
  font-size: 14px;
  margin-bottom: 4px;
`;

const Spacer = styled.div`
  padding: 12px;
`;

const DetailText = styled(Typography)`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Title = styled(Typography)`
  margin: 80px auto 24px;
  font-weight: 700;
  text-align: center;

  ${theme.breakpoints.down("sm")} {
    margin: 64px auto 24px;
  }
`;

export default PartnersInfo;
