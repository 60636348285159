import styled from "styled-components";
import theme from "theme";
import { Trans, useTranslation } from "react-i18next";

import SpecialCampaign from "./SpecialCampaign";
import { getPartnersService } from "api";
import useSWRImmutable from "swr/immutable";
import CampaignCarousel from "./CampaignCarousel";
import Service from "./Service";
import Footer from "containers/Welcome/Footer";
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LoginFooter from "./LoginFooter";
import { ReactComponent as CcfoliaProLogo } from "containers/svg/CcfoliaProLogo.svg";
import { useAppDispatch, useAppSelector } from "stores";
import { getAppState } from "stores/modules/app.state/selectors";
import CampaignDialog from "./CampaignDialog";
import PartnersInfo from "./PartnersInfo";
import { MouseEvent, useCallback, useEffect } from "react";
import { updateUserMarkedPartnersAt } from "stores/modules/entities.user.setting/operations";

const PartnerContent = ({ onOpenLogin }: { onOpenLogin: () => void }) => {
  const [t] = useTranslation();
  const matchMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isAnonymous = useAppSelector((state) =>
    getAppState(state, "isAnonymous")
  );
  const isPro = useAppSelector((state) => {
    return getAppState(state, "plan") === "ccfolia-pro";
  });
  const settingsUid = useAppSelector((state) => state.entities.userSetting.uid);
  const dispatch = useAppDispatch();
  const { data: serviceInfo, isLoading: isLoadingServices } = useSWRImmutable(
    "getPartnersService",
    getPartnersService
  );

  useEffect(() => {
    if (settingsUid) {
      dispatch(updateUserMarkedPartnersAt());
    }
  }, [dispatch, settingsUid]);

  const checkUserPlan = useCallback(
    (e: MouseEvent) => {
      if (isAnonymous) {
        e.preventDefault();
        window.alert(
          t("特別サービスの利用にはログインとCCFOLIA PROへの加入が必要です")
        );
      } else if (!isPro) {
        e.preventDefault();
        window.alert(t("特別サービスの利用はCCFOLIA PROへの加入が必要です"));
      }
    },
    [isAnonymous, isPro, t]
  );

  if (isLoadingServices) {
    return (
      <Box display="flex" justifyContent="center" marginTop="64px">
        <CircularProgress />
      </Box>
    );
  }

  if (!serviceInfo) return null;

  return (
    <>
      <Container>
        <TopText>
          <Trans>
            <StyledCcfoliaProIcon />
            <br style={{ display: matchMd ? "none" : "block" }} />
            で広がる世界
          </Trans>
        </TopText>
        <DetailText>
          <Trans>
            CCFOLIA PROユーザーは、
            <br style={{ display: matchMd ? "none" : "block" }} />
            下記の各種提携サービス・店舗にて
            <br style={{ display: matchSm ? "none" : "block" }} />
            優待を受けることができます。
          </Trans>
        </DetailText>
      </Container>
      <div>
        {serviceInfo.topCampaigns.length > 0 && (
          <CampaignCarousel
            campaigns={serviceInfo.topCampaigns}
            checkUserPlan={checkUserPlan}
          />
        )}
        {serviceInfo.specialCampaign && (
          <SpecialCampaign
            campaign={serviceInfo.specialCampaign}
            checkUserPlan={checkUserPlan}
          />
        )}
        <Service
          services={serviceInfo.services}
          checkUserPlan={checkUserPlan}
        />
      </div>
      <PartnersInfo />
      <Footer mt={125} />
      {(isAnonymous || !isPro) && <LoginFooter onOpenLogin={onOpenLogin} />}
      <CampaignDialog />
    </>
  );
};

const StyledCcfoliaProIcon = styled(CcfoliaProLogo)`
  vertical-align: middle;
  padding-bottom: 6px;
  margin-right: 6px;
  height: 40px;
  width: auto;
  max-width: calc(100vw - 32px);
  fill: ${theme.palette.text.primary};
  ${theme.breakpoints.down("sm")} {
    height: 32px;
    padding-bottom: 0;
  }
`;

const TopText = styled(Typography)`
  font-family: "Noto Sans JP", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  ${theme.breakpoints.down("sm")} {
    margin-top: 40px;
    font-size: 32px;
    line-height: 44px;
  }
`;

const DetailText = styled(Typography)`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  ${theme.breakpoints.down("sm")} {
    margin-top: 16px;
  }
`;

export default PartnerContent;
