import styled from "styled-components";
import theme from "theme";

import { Trans } from "react-i18next";
import { PartnerCampaign } from "api";
import { Card, CardActionArea, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { MouseEvent } from "react";

type SpecialCampaignProps = {
  campaign: PartnerCampaign;
  checkUserPlan: (e: MouseEvent) => void;
};
const SpecialCampaign = ({ campaign, checkUserPlan }: SpecialCampaignProps) => {
  return (
    <MainVisualContainer elevation={0}>
      <AboutWrap>
        <MainVisualTitle color="textPrimary" variant="h4">
          <Trans>
            <Inverter>🎉</Inverter>
            <span style={{ padding: "0 16px" }}>今だけ！特別サービス</span>
            <span>🎉</span>
          </Trans>
        </MainVisualTitle>
        <MainVisualWrap>
          <MainVisualImage>
            <SpecialImageContent
              onClick={checkUserPlan}
              component={Link}
              to={`/partners/${campaign.serviceId}?id=${campaign.campaignId}`}
            >
              <SpecialImage src={campaign.specialOfferImageUrl} />
              <SpecialOffer
                src="/images/special-offer.png"
                alt="special-offer"
              />
            </SpecialImageContent>
          </MainVisualImage>
        </MainVisualWrap>
      </AboutWrap>
    </MainVisualContainer>
  );
};

const Inverter = styled.span`
  display: inline-block;
  transform: scaleX(-1);
`;

const SpecialOffer = styled.img`
  max-width: 72px;
  width: calc(90 / 760 * 100%);
  position: absolute;
  top: 0;
  transform: translateY(calc(-13 / 96 * 100%));
  right: 20px;
  z-index: 100;
  ${theme.breakpoints.down("sm")} {
    width: calc(90 / 742 * 100%);
  }
`;

const AboutWrap = styled.div`
  &::before {
    opacity: 1;
    content: "";
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;

    top: 120px;
    left: calc(-136 / 1400 * 100%);
    width: calc(456 / 1400 * 100%);
    max-width: 456px;
    height: 100%;
    background-image: url("/images/top/dice01.png");
    ${theme.breakpoints.up("xl")} {
      left: 45%;
      right: 50%;
      transform: translateX(calc(-50% + -580px));
    }
    ${theme.breakpoints.down("md")} {
      display: none;
    }
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;

    top: 60px;
    right: calc(-150 / 1500 * 100%);
    width: calc(451 / 1400 * 100%);
    max-width: 451px;

    height: 100%;
    background-image: url("/images/top/dice02.png");
    ${theme.breakpoints.up("xl")} {
      left: 55%;
      right: 45%;
      transform: translateX(calc(-50% + 560px));
    }
    ${theme.breakpoints.down("md")} {
      display: none;
    }
  }
`;

const MainVisualContainer = styled(Card)`
  position: relative;
  display: grid;
  justify-content: center;
  gap: 40px 0;
  width: 100%;
  margin-top: 20px;
  font-family: "Noto Sans JP", sans-serif;
  overflow: hidden;
  background-image: linear-gradient(0deg, transparent 47px, #acacac 48px),
    linear-gradient(90deg, transparent 47px, #acacac 48px);
  background-size: 48px 48px;
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #202020 30px, transparent 200px),
      radial-gradient(rgba(64, 64, 64, 0.6), rgba(32, 32, 32, 1) 80%);
    z-index: 0;
  }

  ${theme.breakpoints.down("sm")} {
    grid-template-columns: 100%;
    gap: 17px 0;
  }
`;

const MainVisualTitle = styled(Typography)`
  position: relative;
  margin: 20px auto 40px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;

  ${theme.breakpoints.down("sm")} {
    margin: 20px auto 17px;
    font-size: 24px;
  }
`;

const MainVisualWrap = styled.div`
  max-width: 790px;
  padding: 0 15px;

  ${theme.breakpoints.down("sm")} {
    max-width: 742px;
    padding: 0 32px;
  }
`;

const MainVisualImage = styled.div`
  position: relative;
  z-index: 2;
  max-width: 760px;
  width: 100%;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 1);
  overflow: visible;

  ${theme.breakpoints.down("md")} {
    box-shadow: 0px 0px 20px 0px #000;
  }
`;

const SpecialImage = styled.img`
  display: block;
  width: 100%;
  cursor: pointer;
`;

const SpecialImageContent = styled(CardActionArea)`
  height: 100%;
  width: 100%;
`;

export default SpecialCampaign;
