import {
  useEffect,
  useCallback,
  useState,
  useRef,
  memo,
  PropsWithChildren,
  useMemo,
} from "react";
import { shallowEqual } from "react-redux";
import { DefaultRootState } from "stores";
import styled from "styled-components";
import { history, useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import theme from "theme";
import version from "version";

import RoomList from "../RoomList";
import Login from "../Login";
import RoomSettings from "../RoomSettings";
import Spacer from "components/Spacer";

import {
  Typography,
  AppBar,
  Avatar,
  Toolbar,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuList,
  Divider,
  Box,
  Paper,
  IconButton,
  Button,
  Hidden,
  Badge,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AppsIcon from "@mui/icons-material/Apps";
import CircleIcon from "@mui/icons-material/Circle";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { Link, Route, Router, useLocation } from "react-router-dom";
import RoomLoader from "containers/Room/RoomLoader";
import RoomSelectionForm from "../RoomSelectionForm";

import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import DiceBotTypeSelect from "containers/DiceBotTypeSelect";
import UserSettings from "../UserSettings";
import UserDeleteDialog from "containers/UserDeleteDialog";
import StripeRedirectObserver from "./StripeRedirectObserver";
import PlanSelect from "containers/PlanSelect";
import ConfirmTermsAgreement from "containers/ConfirmTermsAgreement";
import JoinedProDialog from "containers/JoinedProDialog";
import { ReactComponent as CcfoliaProIcon } from "./CcfoliaPro.svg";
import { ReactComponent as CcfoliaProLogo } from "./CcfoliaProLogo.svg";
import Games from "containers/Games";
import {
  createBillingPortalSession,
  getCampaignsLatestPublishedAt,
  getProductsLatestPublishedAt,
} from "api";
import sendEvent from "modules/sendEvent";
import DiceBotInfo from "containers/Room/DiceBotInfo";
import Library from "containers/Library";
import LoadingPromiseMenuItem from "containers/LoadingPromiseMenuItem/LoadingPromiseMenuItem";
import Product from "containers/Product";
import LibraryProduct from "containers/LibraryProduct";
import { useObserveSigninError } from "modules/signin";

const homeStateSelector = (state: DefaultRootState) => ({
  uid: store.getAppState(state, "uid"),
  roomId: store.getAppState(state, "roomId"),
  openRoomSettings: store.getAppState(state, "openRoomSettings"),
  isAnonymous: store.getAppState(state, "isAnonymous"),
  photoUrl: store.getAppState(state, "photoUrl"),
  roomIds: store.getUserSortedRoomIds(state),
  histories: store.getUserHistoryIds(state),
  displayName: store.getAppState(state, "displayName"),
  isPro: store.getAppState(state, "plan") === "ccfolia-pro",
});

const Home = () => {
  const homeState = useAppSelector(homeStateSelector, shallowEqual);
  const {
    uid,
    roomId,
    isAnonymous,
    photoUrl,
    displayName,
    openRoomSettings,
    isPro,
  } = homeState;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const requiredVerifyEmail = useAppSelector(store.getRequiredVerifyEmail);

  const $ = useEnhance(uid, isAnonymous);
  useEffect(() => {
    if (roomId === null) return;
    const cleanup = dispatch(store.subscribeRoom(roomId));
    return () => {
      dispatch(cleanup);
    };
  }, [dispatch, roomId]);
  useEffect(() => {
    if (openRoomSettings) return;
    dispatch(
      store.appStateMutate((state) => {
        // タイミングに依っては roomId が null に出来てないかも
        state.roomId = null;
      })
    );
  }, [dispatch, openRoomSettings]);

  const onClickPaymentLog = useCallback(async () => {
    try {
      const { url } = await createBillingPortalSession();
      if (url) {
        window.location.href = url;
      }
    } catch {
      window.alert(t("購入情報がないため、ページを開けませんでした。"));
    }
  }, [t]);

  const tabValue = useMemo(() => {
    if ($.location.pathname.startsWith("/games")) {
      return "/games";
    } else if ($.location.pathname.startsWith("/library")) {
      return "/library";
    }

    return $.location.pathname;
  }, [$.location.pathname]);

  useObserveSigninError({ openLoginDialog: $.onOpenLoginForm });

  return (
    <Wrapper>
      {uid !== null && roomId !== null && (
        <>
          <DiceBotTypeSelect uid={uid} roomId={roomId} />
          <RoomSettings roomId={roomId} />
          <DiceBotInfo />
        </>
      )}
      {!isAnonymous && uid ? ( // null === signouted
        <Menu
          open={$.openMenu}
          onClose={$.onClickAvatar}
          anchorEl={$.anchorEl.current}
          style={{ zIndex: theme.zIndex.modal + 2 }}
        >
          <List disablePadding>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt={
                    isAnonymous
                      ? t("ゲストユーザー")
                      : displayName || t("匿名ユーザー")
                  }
                  src={photoUrl || "/ccfolia.png"}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  isAnonymous
                    ? t("ゲストユーザー")
                    : displayName || t("匿名ユーザー")
                }
                secondary={isAnonymous ? t("SNS未認証") : t("SNS認証済み")}
              />
            </ListItem>
          </List>
          <MenuList>
            <MenuItem component={Link} to="/">
              {t("ホームに戻る")}
            </MenuItem>
            <MenuItem onClick={$.onClickLogout}>{t("ログアウト")}</MenuItem>
          </MenuList>
          <Divider />
          {!isAnonymous && uid && (
            <>
              <MenuList>
                <MenuItem onClick={$.onClickSettings}>
                  {t("アカウント設定")}
                  {requiredVerifyEmail && (
                    <CircleIcon
                      color="secondary"
                      style={{ fontSize: "8px", marginLeft: "8px" }}
                    />
                  )}
                </MenuItem>
                <LoadingPromiseMenuItem onClick={onClickPaymentLog}>
                  {t("購入履歴")}
                </LoadingPromiseMenuItem>
              </MenuList>
              <Divider />
            </>
          )}
          <MenuList>
            <MenuItem
              component="a"
              href="https://docs.ccfolia.com/"
              target="_blank"
            >
              {t("ヘルプ")}
            </MenuItem>
            <MenuItem component="a" href="/termsOfService.html" target="_blank">
              {t("利用規約")}
            </MenuItem>
            <MenuItem component="a" href="/privacyPolicy.html" target="_blank">
              {t("プライバシーポリシー")}
            </MenuItem>
          </MenuList>
        </Menu>
      ) : null}
      <AppBar
        color="default"
        position="sticky"
        // style={{ zIndex: theme.zIndex.modal + 1, background: "transparent" }}
      >
        <Toolbar>
          <Link to="/">
            <Avatar src="/android-chrome-192x192.png" />
          </Link>
          <Typography variant="h6" style={{ marginLeft: 8 }}>
            CCFOLIA
          </Typography>
          <Typography variant="caption"> {version}</Typography>
          <Spacer />
          {isPro && (
            <Button
              variant="text"
              color="white"
              component={Link}
              to="/partners"
              style={{
                marginRight: theme.spacing(1),
                textTransform: "none",
                fontWeight: "normal",
              }}
              target="_blank"
            >
              <Badge
                color="secondary"
                variant="dot"
                invisible={!$.enableBadgePartnersButton}
              >
                <Hidden smDown>🎁 {t("CCFOLIA PRO特典")}</Hidden>
                <Hidden smUp>🎁 {t("PRO特典")}</Hidden>
              </Badge>
            </Button>
          )}
          {!isPro && !isAnonymous && uid != null && (
            <Button
              variant="text"
              color="white"
              onClick={$.onClickPlanSelect}
              style={{
                marginRight: theme.spacing(1),
                textTransform: "none",
                fontWeight: "normal",
              }}
            >
              <Hidden smDown>
                <StyledCcfoliaProLogo role="img" aria-label="CCFOLIA PRO" />
                {t("になる")} 🎉
              </Hidden>
              <Hidden smUp>
                <StyledCcfoliaProIcon role="img" aria-label="CCFOLIA PRO" />
                <Typography variant="caption">{t("になる")}</Typography>
              </Hidden>
            </Button>
          )}
          <IconButton
            size="small"
            edge="end"
            onClick={$.onClickAvatar}
            ref={$.anchorEl}
          >
            <Badge
              color="secondary"
              variant="dot"
              overlap="circular"
              invisible={!requiredVerifyEmail}
            >
              <Avatar src={toCDNUrl(photoUrl) || "/ccfolia.png"} />
            </Badge>
          </IconButton>
        </Toolbar>
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
          <Tab
            icon={<MeetingRoomOutlinedIcon fontSize="small" />}
            iconPosition="start"
            label={t("マイルーム")}
            value="/home"
            component={Link}
            to="/home"
            sx={{ minHeight: "48px" }}
          />
          <Tab
            icon={<StorefrontIcon fontSize="small" />}
            iconPosition="start"
            label={
              <Badge
                color="secondary"
                variant="dot"
                invisible={!$.enableBadgeGamesTab}
              >
                {t("ストア")}
              </Badge>
            }
            value="/games"
            component={Link}
            to="/games"
            onClick={$.onClickGamesTab}
            sx={{ minHeight: "48px" }}
          />
          <Tab
            icon={<AppsIcon fontSize="small" />}
            iconPosition="start"
            label={t("ライブラリ")}
            value="/library"
            component={Link}
            to="/library"
            sx={{ minHeight: "48px" }}
          />
        </Tabs>
      </AppBar>
      <Router history={history}>
        <Route exact path="/home">
          <HomeContent homeState={homeState} $={$} />
        </Route>
        <Route exact path="/games" component={Games} />
        <Route exact path="/games/:productId" component={Product} />
        <Route exact path="/library" component={LibraryContent} />
        <Route
          exact
          path="/library/:productId"
          component={LibraryProductContent}
        />
      </Router>
      <UserSettings uid={uid} />
      <PlanSelect />
      <UserDeleteDialog />
      <StripeRedirectObserver />
      <ConfirmTermsAgreement />
      <JoinedProDialog />
      <Login open={$.openLoginForm} onClose={$.onCloseLoginForm} />
    </Wrapper>
  );
};

type HomeContentProps = {
  homeState: ReturnType<typeof homeStateSelector>;
  $: ReturnType<typeof useEnhance>;
};

const HomeContent = (_props: HomeContentProps) => {
  const props = _props.homeState;
  const $ = _props.$;
  if (!props.uid) {
    return (
      <Box display="flex" justifyContent="center" marginTop="64px">
        <CircularProgress />
      </Box>
    );
  }
  if (props.isAnonymous) {
    return <Login isHome open />;
  }

  return (
    <>
      <Box padding={2} paddingBottom={1}>
        <Paper>
          <RoomSelectionForm />
        </Paper>
      </Box>
      <RoomList
        list={props.roomIds} // 本来はRoomList側のselectorでやる
      />
      <FloatFab onClick={$.onAddRoom} color="secondary">
        <AddIcon />
      </FloatFab>
    </>
  );
};

const LibraryContent = () => {
  return (
    <NeedLogin>
      <Library />
    </NeedLogin>
  );
};

const LibraryProductContent = () => {
  return (
    <NeedLogin>
      <LibraryProduct />
    </NeedLogin>
  );
};

type NeedLoginProps = PropsWithChildren<{}>;

const NeedLogin = ({ children }: NeedLoginProps) => {
  const uid = useAppSelector((state) => store.getAppState(state, "uid"));
  const isAnonymous = useAppSelector((state) =>
    store.getAppState(state, "isAnonymous")
  );

  if (!uid) {
    return <RoomLoader loading={true} />;
  }
  if (isAnonymous) {
    return <Login isHome open />;
  }

  return <>{children}</>;
};

const AUTH_REQUIRED_PREFIXES = ["/home", "/library"];

const useEnhance = (uid: string | null, isAnonymous: boolean | null) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const cleanup = dispatch(store.subscribeUserState());
    return () => {
      dispatch(cleanup);
    };
  }, [dispatch]);

  useEffect(() => {
    if (!uid) return;
    const cleanup = dispatch(store.subscribeUserRooms({}));
    return () => {
      dispatch(cleanup);
    };
  }, [dispatch, uid]);

  useSubscribeUserSettings();

  useEffect(() => {
    if (!uid) return;
    const cleanup = dispatch(store.subscribeUserHistories(uid));
    return () => {
      dispatch(cleanup);
    };
  }, [dispatch, uid]);

  const [openMenu, setOpenMenu] = useState(false);
  const anchorEl = useRef(null);

  const onClickLogout = useCallback(() => {
    dispatch(store.signOut());
    setOpenMenu(false);
  }, [dispatch]);

  const [openLoginForm, setOpenLoginForm] = useState(false);

  const onClickAvatar = useCallback(() => {
    if (isAnonymous) {
      setOpenLoginForm(true);
    } else {
      setOpenMenu((value) => !value);
    }
  }, [setOpenMenu, setOpenLoginForm, isAnonymous]);

  const onOpenLoginForm = useCallback(() => {
    if (
      !AUTH_REQUIRED_PREFIXES.some((prefix) =>
        location.pathname.startsWith(prefix)
      )
    ) {
      setOpenLoginForm(true);
    }
  }, [setOpenLoginForm, location.pathname]);

  const onCloseLoginForm = useCallback(() => {
    setOpenLoginForm(false);
  }, [setOpenLoginForm]);

  const onAddRoom = useCallback(() => {
    dispatch(store.addRoomWithRoomSettings());
  }, [dispatch]);

  const onClickSettings = useCallback(() => {
    onClickAvatar();
    dispatch(store.appStateMutate((state) => (state.openUserSettings = true)));
  }, [dispatch, onClickAvatar]);

  const onClickPlanSelect = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openPlanSelect = true;
        state.destOnClosePlanSelect = "none";
      })
    );
  }, [dispatch]);

  const [gamesLatestPublishedAt, setGamesLatestPublishedAt] =
    useState<number>();
  const markedGamesAt = useAppSelector(
    (state) => state.entities.userSetting.markedGamesAt
  );
  const markedPartnersAt = useAppSelector(
    (state) => state.entities.userSetting.markedPartnersAt
  );
  const [partnersLatestPublishedAt, setPartnersLatestPublishedAt] =
    useState<number>();

  useEffect(() => {
    if (isAnonymous || isAnonymous == null) {
      return;
    }

    getProductsLatestPublishedAt().then((publisedAt) => {
      setGamesLatestPublishedAt(Date.parse(publisedAt));
    });
    getCampaignsLatestPublishedAt().then((publisedAt) => {
      setPartnersLatestPublishedAt(Date.parse(publisedAt));
    });
  }, [isAnonymous, setGamesLatestPublishedAt]);

  const authedUid = useAppSelector(store.getAuthedUid);
  const enableBadgeGamesTab =
    authedUid &&
    gamesLatestPublishedAt &&
    (markedGamesAt == null ||
      gamesLatestPublishedAt > markedGamesAt.toMillis());
  const settingsUid = useAppSelector((state) => state.entities.userSetting.uid);
  const enableBadgePartnersButton =
    authedUid &&
    partnersLatestPublishedAt &&
    (markedPartnersAt == null ||
      partnersLatestPublishedAt > markedPartnersAt.toMillis());

  useEffect(() => {
    if (location.pathname === "/games" && enableBadgeGamesTab) {
      dispatch(store.updateUserMarkedGamesAt());
    }
  }, [dispatch, location.pathname, enableBadgeGamesTab]);

  useEffect(() => {
    if (settingsUid) {
      dispatch(store.updateUserAccessStatus());
    }
  }, [dispatch, settingsUid]);

  const onClickGamesTab = useCallback(() => {
    sendEvent("clickTab", {
      event_category: "games",
    });
  }, []);

  return {
    onClickLogout,
    onClickAvatar,
    openMenu,
    anchorEl,
    onAddRoom,
    onClickSettings,
    onClickPlanSelect,
    onClickGamesTab,
    location,
    enableBadgeGamesTab,
    enableBadgePartnersButton,
    openLoginForm,
    onOpenLoginForm,
    onCloseLoginForm,
  };
};

const useSubscribeUserSettings = () => {
  const dispatch = useAppDispatch();
  const authedUid = useAppSelector(store.getAuthedUid);
  const settingsUid = useAppSelector((state) => state.entities.userSetting.uid);

  useEffect(() => {
    if (authedUid) {
      dispatch(store.loadUserSetting(authedUid));
    }
  }, [dispatch, authedUid]);

  // 利用規約同意モーダルの意図しない表示を避けるために、一度ロードされてから購読するようにする
  useEffect(() => {
    if (settingsUid) {
      return dispatch(store.subscribeUserSetting(settingsUid));
    }
  }, [dispatch, settingsUid]);
};

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const FloatFab = styled(Fab)`
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 103;
`;

const StyledCcfoliaProIcon = styled(CcfoliaProIcon)`
  margin-right: 4px;
  fill: ${theme.palette.text.primary};
`;

const StyledCcfoliaProLogo = styled(CcfoliaProLogo)`
  margin-right: 4px;
  fill: ${theme.palette.text.primary};
`;

export default memo(Home);
