import { Box, Link, Typography } from "@mui/material";
import { memo } from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import styled from "styled-components";

type MarkdownContentProps = {
  text: string;
};

const MarkdownContent = ({ text }: MarkdownContentProps) => {
  return (
    <>
      <ReactMarkdown
        remarkPlugins={[remarkBreaks]}
        components={{
          h1: ({ children }) => (
            <Box display="flex" justifyContent="center">
              <HeadingBox>
                <Typography fontWeight={700} variant="h5">
                  {children}
                </Typography>
              </HeadingBox>
            </Box>
          ),
          h2: ({ children }) => (
            <HeadingText fontWeight={700} gutterBottom>
              {children}
            </HeadingText>
          ),
          h3: ({ children }) => (
            <HeadingText fontWeight={700} gutterBottom>
              {children}
            </HeadingText>
          ),
          h4: ({ children }) => (
            <HeadingText fontWeight={700} gutterBottom>
              {children}
            </HeadingText>
          ),
          h5: ({ children }) => (
            <HeadingText fontWeight={700} gutterBottom>
              {children}
            </HeadingText>
          ),
          h6: ({ children }) => (
            <HeadingText fontWeight={700} gutterBottom>
              {children}
            </HeadingText>
          ),
          p: ({ children }) => <Typography>{children}</Typography>,
          ul: ({ children }) => (
            <ListUl>
              <Typography>{children}</Typography>
            </ListUl>
          ),
          ol: ({ children }) => (
            <ListOl>
              <Typography>{children}</Typography>
            </ListOl>
          ),
          li: ({ children }) => (
            <ListLi>
              <Typography>{children}</Typography>
            </ListLi>
          ),
          a: ({ href, children }) => (
            <Link href={href} target="_blank">
              {children}
            </Link>
          ),
        }}
      >
        {text}
      </ReactMarkdown>
    </>
  );
};

const HeadingBox = styled(Box)`
  display: inline-block;
  padding: 0 16px 12px;
  border-bottom: 2px solid #ffffff;
  margin-bottom: 16px;
`;

const HeadingText = styled(Typography)`
  margin-top: 12px;
`;

const ListUl = styled.ul`
  padding-left: 20px;
  margin: 3px 0;
`;

const ListOl = styled.ol`
  padding-left: 20px;
  margin: 3px 0;
`;

const ListLi = styled.li`
  margin: 3px 0;
`;

export default memo(MarkdownContent);
