import styled from "styled-components";
import theme from "theme";

import {
  Typography,
  Box,
  Button,
  ButtonProps,
  useMediaQuery,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { memo, useCallback } from "react";
import { ReactComponent as CcfoliaProLogo } from "containers/svg/CcfoliaProLogo.svg";
import { useAppDispatch, useAppSelector } from "stores";
import { getAppState } from "stores/modules/app.state/selectors";
import { appStateMutate } from "stores/modules/app.state/operations";

type LoginFooterProps = {
  onOpenLogin: () => void;
};

const LoginFooter = ({ onOpenLogin }: LoginFooterProps) => {
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const isAnonymous =
    useAppSelector((state) => getAppState(state, "isAnonymous")) ?? true;

  const onClickPlanSelect = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openPlanSelect = true;
        state.destOnClosePlanSelect = "none";
      })
    );
  }, [dispatch]);

  return (
    <>
      <FooterPosition />
      <FooterContainer>
        <PositionContainer>
          <DetailText>
            <Trans>
              特別サービスを獲得するには
              <br style={{ display: matchSm ? "none" : "block" }} />
              <StyledCcfoliaProIcon />
              への加入が必要です
            </Trans>
          </DetailText>
          <LoginButton
            size="large"
            variant="contained"
            fullWidth
            color="secondary"
            onClick={isAnonymous ? onOpenLogin : onClickPlanSelect}
          >
            {isAnonymous ? t("ログイン") : t("Proに加入")}
          </LoginButton>
        </PositionContainer>
      </FooterContainer>
    </>
  );
};

const StyledCcfoliaProIcon = styled(CcfoliaProLogo)`
  height: 14px;
  margin: 0 4px;
  fill: ${theme.palette.text.primary};
`;

const PositionContainer = styled(Box)`
  text-align: center;
  padding: 43px 0;
  ${theme.breakpoints.down("sm")} {
    padding: 16px 0 44px;
  }
`;

const LoginButton = styled(Button)<ButtonProps<"a">>`
  max-width: 280px;
  height: 54px;
  margin-top: 20px;
  font-size: 18px;
  ${theme.breakpoints.down("sm")} {
    max-width: 240px;
    height: 48px;
    font-size: 16px;
  }
`;

const DetailText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
`;

const FooterPosition = styled(Box)`
  height: 180px;
`;

const FooterContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 180px;
  background: #000000de;
  color: #fff;
  z-index: 100;
`;

export default memo(LoginFooter);
